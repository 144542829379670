import { defineStore } from "pinia";
import { actions } from "./actions";
import { getters } from "./getters";

export const useSessionStore = defineStore({
  id: "session",
  state: () => ({
    userId: localStorage.getItem("userId"),
    userToken: localStorage.getItem("userToken"),
    userInfo: null,
    showAuthModal: false,
    showSignUpModal: false,
    showForgotPasswordModal: false,
    showPasswordRecoveryModal: false,
  }),
  getters,
  actions,
});
