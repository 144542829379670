
<template>
  <div class="bg-white py-2 grid grid-cols-9 h-[60px] md:h-[70px] w-full">
    <div class="px-3 col-span-2">
      <img src="@/assets/allhouse.svg" class="hidden md:flex navbar-logo" />
      <img
        src="@/assets/allhouse-black.png"
        class="flex md:hidden w-10"
      />
    </div>
    <div class="col-span-2 md:col-span-4 lg:col-span-5" >
      
    </div>
    <div class="px-3 col-span-5 md:col-span-3 lg:col-span-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2">
      <button>
        <a
          href="https://dashboard.allhouse.co.nz"
          class="py-2 px-3 text-center text-black hidden text-base lg:block"
          >Managers' Portal</a
        >
      </button>
      <button
        class="text-black text-base px-3 py-2"
        v-if="!userToken"
        @click="showAuthModal = true"
      >
        Sign In
      </button>
      <button v-else class="text-black text-base px-3 py-2" @click="logout">
        Logout
      </button>
    </div>
  </div>
  
</template>

<script>
import { useSessionStore } from "@/store/session";
import { mapState } from "pinia";

export default {
  name: "NavBar",
  components: { },
  data: () => ({
    searchOpen: false,
  }),
  computed: {
    ...mapState(useSessionStore, ["userToken"]),
  },
  methods: {
    logout() {
      localStorage.removeItem("userId");
      localStorage.removeItem("userToken");
      window.location.reload();
    },
    
  },
};
</script>

<style scoped>
.navbar-logo {
  max-height: 50px;
  width: auto;
}
button:hover{
  color:#a7d9aa
}
a:hover{
  color:#a7d9aa
}
</style>
