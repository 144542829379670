import axios from "axios";
import { API_URL, GENERAL_ERROR } from "@/utils/constants";
import { toast } from "vue3-toastify";
import { useSessionStore } from "@/store/session";
import { useAppStore } from "@/store/app";

export const actions = {
  async getRegions() {
    try {
      useAppStore().$patch({
        loading: true,
      });
      const { data } = await axios({
        method: "GET",
        url: `${API_URL}/regions`,
      });
      this.$patch({
        regions: data,
      });
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
  async getListings({
    options,
    status = { $nin: ["inactive", "hold"] },
    filter,
  }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const {
        data: { listings },
      } = await axios({
        url: `${API_URL}/listings?options=${JSON.stringify(
          options
        )}&status=${JSON.stringify(status)}&filter=${
          filter ? JSON.stringify(filter) : ""
        }`,
      });
      useAppStore().$patch({
        loading: false,
      });
      return listings;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
  async getSearchResults() {
    let filter = {
      type: "custom-search",
      rentRange: this.priceRange,
      bedrooms: this.bedrooms,
      bathrooms: this.bathrooms,
    };

    if (this.selectedRegion) filter.stateOrRegion = this.selectedRegion.name;
    if (this.selectedDistrict) filter.district = this.selectedDistrict.name;
    // if (this.selectedSuburbs) filter.suburb = this.selectedSuburbs;
    if (this.selectedSuburbs && this.selectedSuburbs.length > 0)
      filter.suburb = this.selectedSuburbs;
      console.log("district length: ", this.selectedDistrict)
      console.log("suburbs length: ", filter.suburb)

    const listings = await this.getListings({
      options: { sort: "description.rent", limit: 100 },
      filter,
    });
    this.$patch({
      searchResults: listings,
      showSearchResults: true,
      
    });
  },
  async getLatestListings() {
    const listings = await this.getListings({
      options: {
        sort: "-advertising.listedAt",
        limit: 32,
      },
    });
    this.$patch({
      latestListings: listings,
    });
  },
  async getTrendingListings() {
    const listings = await this.getListings({
      filter: { type: "recently-booked" },
      options: {
        limit: 32,
      },
    });
    this.$patch({
      trendingProperties: listings,
    });
  },
  async getCoolestPaths() {
    const listings = await this.getListings({
      options: {
        sort: "-description.rent",
        limit: 32,
      },
    });
    this.$patch({
      coolestPads: listings,
    });
  },
  async getListing({ listingId }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const { userToken } = useSessionStore();
      const {
        data: { listing },
      } = await axios({
        url:
          listingId.length > 8
            ? `${API_URL}/listings/${listingId}`
            : `${API_URL}listings/by-property-code/${listingId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      useAppStore().$patch({
        loading: false,
      });
      return listing;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: true,
    });
  },
  async requestViewing({ viewingInfo, listingId }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const { userToken } = useSessionStore();
      const { data } = await axios({
        url: `${API_URL}/api/matches`,
        method: "POST",
        data: {
          ...viewingInfo,
          id: listingId,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      useAppStore().$patch({
        loading: false,
      });
      return data;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
};
