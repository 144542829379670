<template>
  <modal-component
    ref="modal"
    @close="selectedListing = null"
    size="fullscreen"
  >
    <div class="w-full p-5 h-vh overflow-hidden pb-[112px] md:pb-5">
      <description-page :selected-listing="selectedListing" />

      
    </div>
    <div class="grid grid-cols-4 fixed md:relative bottom-0 right-0 left-0 bg-white">
  
    <div class="col-span-2">
      <div class="py-2 px-3 ">
        <button
          class="bg-gray-200 text-black uppercase py-2 px-3 w-full border-1rem"
          @click="selectedListing = null"
        >
          <fa-icon icon="fa-arrow-left-long" />
          Back
        </button>
      </div>
      
    </div>
    <div class="col-span-2">
      <div class="py-2 px-3" v-if="!userToken">
        <button
          class="py-2 px-3 text-dark uppercase view-button w-full"
          @click="showAuthModal = true"
        >
          Sign In to View/Apply
        </button>
      </div>
      <div class="py-2 px-3" v-else>
        <button
          v-if="hasTPSApplicationForm"
          class="py-2 px-3 text-dark uppercase view-button w-full"
          @click="applyToListing"
        >
          View/Apply
        </button>
        <button
          v-else
          class="py-2 px-3 text-dark uppercase view-button w-full"
          @click="requestViewing"
        >
          Request Viewing
        </button>
      </div>
    </div>
    </div>

    
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/Modal";
import { mapWritableState } from "pinia";
import { useListingStore } from "@/store/listing";
import DescriptionPage from "@/components/DescriptionSection";
import { useSessionStore } from "@/store/session";
import { toast } from "vue3-toastify";
export default {
  name: "ListingModal",
  components: { DescriptionPage, ModalComponent },
  data: () => ({
    tab: "description",
  }),
  computed: {
    ...mapWritableState(useListingStore, ["selectedListing"]),
    ...mapWritableState(useSessionStore, ["userToken", "showAuthModal"]),
    hasTPSApplicationForm() {
      if (
        !this.selectedListing.integrations ||
        !this.selectedListing.integrations.tps ||
        !this.selectedListing.integrations.tps.applicationForm
      )
        return false;
      return true;
    },
  },
  methods: {
    applyToListing() {
      window.open(
        this.selectedListing.integrations.tps.applicationForm,
        "_blank"
      );
      this.$gtag.event("apply-to-listing", {
        event_category: "listings",
        event_label: "a TPS listing application has been opened",
        value: this.selectedListing.location.address.streetAddress,
      });
    },
    async requestViewing() {
      const success = await useListingStore().requestViewing({
        viewingInfo: {
          viewingTime: {},
        },
        listingId: this.selectedListing._id,
      });
      if (!success) return;
      toast.success(
        "Request sent successfully, the property agent will get in touch with you shortly."
      );
      this.$gtag.event("request-listing-viewing", {
        event_category: "listings",
        event_label: "a manual request to view a property",
        value: this.selectedListing.location.address.streetAddress,
      });
      this.selectedListing = null;
    },
  },
  watch: {
    selectedListing(newVal) {
      this.$refs.modal.open = newVal ? true : false;
      this.tab = "description";
    },
  },
};
</script>

<style scoped>
.view-button{
  background-color: #a7d9aa;
  border-radius: 1rem;
}
.border-1rem{
  border-radius:1rem;
}

</style>
