
<template>
  <div
    class="w-full h-full overflow-scroll py-5 px-5 md:px-10 bg-center "
  >
  <div class="flex flex-col items-center justify-center">
    <button
        class="text-black text-base px-3 py-2 border-black rounded-full border-[1px] w-5/6 green-hover transition-all"
        @click="searchOpen = !searchOpen"
      >
        Search <fa-icon icon="search" />
      </button>
      <div
    class="bg-white text-3xl max-h-0 transition-max-height duration-500 ease-in-out overflow-hidden"
    v-bind:class="{ 'max-h-[900px]': searchOpen, 'h-fit': searchOpen }"
  >
    <div
      class="w-full md:w-5/6 mx-auto px-3 grid grid-cols-2 md:grid-cols-7 text-base gap-4 py-5"
    >
      <div>
        <!-- there's a runtime error when you don't select a district/suburb/region sometimes 
          but it is like that on the current working site too-->
        <label>Region</label>
        <select
          v-model="selectedRegion"
          class="decorated appearance-none bg-white text-black border-gray-500 border-[1px] rounded-md py-2 px-3 w-full"
        >
          <option :value="null">All New Zealand</option>
          <option v-for="region of sortedRegions" :key="region.name" :value="region">
            {{ region.name }}
          </option>
        </select>
        <!-- <label>Region</label>
        <select-dropdown
          v-model="selectedRegion"
          :options="sortedRegions"
          :multiple="false"
          :minSelection=0
          no-selection-label="All NZ"
          style="font-size:50%"
        /> -->
      </div>
      <div>
        <label>District</label>
        <!-- <select-dropdown
          v-model="selectedDistrict"
          :options="sortedDistricts"
          :multiple="false"
          :minSelection=0
          no-selection-label="All Districts"
        /> -->
        <select
          v-model="selectedDistrict"
          :disabled="!selectedRegion"
          class="decorated appearance-none bg-white text-black border-gray-500 border-[1px] rounded-md py-2 px-3 w-full"
        >
          <option :value="null">All Districts</option>
          <option
            v-for="district of sortedDistricts"
            :key="district.name"
            :value="district"
          >
            {{ district.name }}
          </option>
        </select>
      </div>

      <div>
        <label>Suburb</label>
        <select-dropdown
          v-model="selectedSuburbs"
          :options="sortedSuburbs"
          :multiple="true"
          :minSelection=0
          no-selection-label="All Suburbs"
        />

        <!-- <label>Suburb</label>
        <select
          v-model="selectedSuburbs"
          :disabled="!selectedDistrict || !selectedRegion"
          class="appearance-none bg-white text-black border-gray-500 border-[1px] rounded-md py-2 px-3 w-full"
          >
          <option :value="null">All Suburbs</option>
          <option
            v-for="suburb of sortedSuburbs"
            :key="suburb"
            :value="suburb"
          >
            {{ suburb }}
          </option>
        </select> -->
      </div>
      <div>
        <label>Min Price</label>
          <div class="grid grid-cols-5 border-[1px] border-gray-500 rounded-md">
            <div
              class="border-r-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
              @click="updateMinPrice(-50)"
            >
              <fa-icon icon="minus" />
            </div>
            <div class="col-span-3 py-2 text-center">${{ priceRange[0] }}</div>
            <div
              class="border-l-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
              @click="updateMinPrice(50)"
            >
              <fa-icon icon="plus" />
            </div>
          
          </div>
        </div>
        <div>
          <label> Max Price</label>
          <div class="grid grid-cols-5 border-[1px] border-gray-500 rounded-md">
           
            <div
              class="border-r-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
              @click="updateMaxPrice(-50)"
            >
              <fa-icon icon="minus" />
            </div>
            <div class="col-span-3 py-2 text-center">${{ priceRange[1] }}</div>
            <div
              class="border-l-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
              @click="updateMaxPrice(50)"
            >
              <fa-icon icon="plus" />
            </div>
          </div>
        </div>
    
      
      <div>
        <label>Bedrooms</label>
        <div class="grid grid-cols-5 border-[1px] border-gray-500 rounded-md">
          <div
            class="border-r-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
            @click="bedrooms = bedrooms - 1 >= 0 ? bedrooms - 1 : bedrooms"
          >
            <fa-icon icon="minus" />
          </div>
          <div class="col-span-3 py-2 text-center">
            {{ bedrooms > 0 ? `${bedrooms}+` : "Any" }}
          </div>
          <div
            class="border-l-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
            @click="bedrooms += 1"
          >
            <fa-icon icon="plus" />
          </div>
        </div>
      </div>
      <div>
        <label>Bathrooms</label>
        <div class="grid grid-cols-5 border-[1px] border-gray-500 rounded-md">
          <div
            class="border-r-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
            @click="bathrooms = bathrooms - 1 >= 0 ? bathrooms - 1 : bathrooms"
          >
            <fa-icon icon="minus" />
          </div>
          <div class="col-span-3 py-2 text-center">
            {{ bathrooms > 0 ? `${bathrooms}+` : "Any" }}
          </div>
          <div
            class="border-l-[1px] border-gray-500 border-solid text-center py-2 cursor-pointer green-hover"
            @click="bathrooms += 1"
          >
            <fa-icon icon="plus" />
          </div>
        </div>
      </div>
   
      
      <div class="col-span-2 md:col-span-4">
        <button
          type="button"
          class="uppercase px-3 py-2 w-full"
          style="background-color:#a7d9aa;border-radius:2rem;"
          @click="search"
        >
          search
        </button>
      </div>
      
      <div class="col-span-2 md:col-span-3">
        <button
          type="button"
          class="bg-gray-200 text-black uppercase px-3 py-2 w-full"
          style="border-radius:2rem;"
          @click="searchOpen = false"
        >
          close
        </button>
      </div>
    </div>
  </div>
    </div>
    <br />
    <div class="flex justify-center space-x-4 mb-4">
      <button 
        class="green-hover text-black px-4 py-2 rounded-md flex items-center"
        @click="setActiveCategory('latest')">
        <img
        src="@/assets/icons/icon-Rocket-ship.png"
        style="width: 25px"
      />
        <span :class="{ 'highlighted': activeCategory === 'latest' && !showSearchResults}">Latest</span>
      </button>
      <button 
        class="green-hover text-black px-4 py-2 rounded-md flex items-center"
        @click="setActiveCategory('coolest')">
        <img
        src="@/assets/icons/icon-emoji-smile.png"
        style="width: 25px"
      />
        <span :class="{ 'highlighted': activeCategory === 'coolest' && !showSearchResults}">Coolest</span>
      </button>
      <button
        class="green-hover text-black px-4 py-2 rounded-md flex items-center"
        @click="setActiveCategory('trending')">
        <img src="@/assets/icons/Icon-Fire.png" style="width: 25px"/>
        <span :class="{ 'highlighted': activeCategory === 'trending' && !showSearchResults}">Trending</span>
      </button>
    </div>
    <div
      class="flex text-2xl bg-white py-2 rounded-md"
      v-if="showSearchResults"
    >
    
      <img
        src="@/assets/icons/allhouse_Illustrations_Search.png"
        style="width: 30px"
      />Your Search Results
    </div>
    <!-- {{ searchResults }} -->
    <ListingsReel v-if="showSearchResults && this.searchResults.length > 0" :listings="searchResults" />
    <div
      v-if="showSearchResults && this.searchResults.length <1"
      class="bg-alert rounded-md my-5 py-3"
    >
      <h3 class="text-center flex text-3xl w-full justify-center">
        Oops!
        <img src="@/assets/icons/monster.svg" width="50" />
      </h3>
      <h4 class="text-center w-full text-xl">
        There aren't any properties in this category right now
        <br />
        New properties are listed daily, so come back tomorrow
      </h4>
    </div>
  <div v-if="!showSearchResults">
    
    <div v-if="activeCategory === 'latest'" class="text-2xl bg-white py-2 rounded-md">
      <ListingsReel :listings="latestListings" />
    </div>
    <div v-if="activeCategory === 'coolest'" class="text-2xl bg-white py-2 rounded-md">
      <ListingsReel :listings="coolestPads" />
    </div>
    <div v-if="activeCategory === 'trending'" class="text-2xl bg-white py-2 rounded-md">
      <ListingsReel :listings="trendingProperties" />
    </div>
  </div> 
</div>
</template>

<script>
import ListingsReel from "@/components/ListingsReel";
import { mapState, mapWritableState } from "pinia";
import { useSessionStore } from "@/store/session";
import { useListingStore } from "@/store/listing";
import SelectDropdown from "@/components/SelectDropdown";

export default {
  name: "ListingsView",
  components: { ListingsReel, SelectDropdown },
  data: () => ({
    searchOpen: false,
    activeCategory: 'coolest',
  }),
  computed: {
    ...mapState(useSessionStore, ["userToken"]),
    ...mapState(useListingStore, ["regions"]),
    ...mapState(useListingStore, [
      "selectedRegion",
      "selectedDistrict",
      "selectedSuburbs",
      "priceRange",
      "bedrooms",
      "bathrooms",
      "searchResults",
      "showSearchResults",
      "latestListings",
      "coolestPads",
      "trendingProperties"
    ]),
    ...mapWritableState(useListingStore,[
      "selectedRegion",
      "selectedDistrict",
      "selectedSuburbs",
      "priceRange",
      "bedrooms",
      "bathrooms",
      "showSearchResults"
    ]),
    ...mapWritableState(useSessionStore, ["showAuthModal"]),
    sortedRegions() {
      return [...this.regions].sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedDistricts() {
      return [...(this.selectedRegion?.districts ?? [])].sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedSuburbs() {
      return [...(this.selectedDistrict?.suburbs ?? [])].sort((a, b) => a.localeCompare(b));
    },
    districts() {
      if (!this.selectedRegion) return [];
      return this.selectedRegion.districts;
    },
    suburbs() {
      if (!this.selectedDistrict || !this.selectedRegion) return [];
      return this.selectedDistrict.suburbs;
    },

  },
  methods:{
    setActiveCategory(category) {
      if (this.showSearchResults) {
      this.showSearchResults = false;
    }
      this.activeCategory = category;
      console.log("search results: ", this.showSearchResults);
      
    },
    async search() {
      if(!this.selectedRegion){
        this.selectedDistrict=null;
        this.selectedSuburbs=[];
      }
      if (!this.selectedDistrict) {
        this.selectedSuburbs = [];
       }
      await useListingStore().getSearchResults();
      this.searchOpen = false;
      this.$gtag.event("search", {
        event_category: "listings",
        event_label: "A new listings search has been performed",
        value: `region:${this.selectedRegion.name}-district:${
          this.selectedDistrict.name
        }-suburb:${this.selectedSuburbs}`,
      });
    },
    updateMinPrice(val) {
      if (
        this.priceRange[0] + val < 0 ||
        this.priceRange[0] + val >= this.priceRange[1]
      )
        return;
      this.priceRange[0] += val;
    },
    updateMaxPrice(val) {
      if (
        this.priceRange[1] + val <= this.priceRange[0] ||
        this.priceRange[1] + val > 10000
      )
        return;
      this.priceRange[1] += val;
    },

  },
};
</script>

<style scoped>

.green-hover:hover{
  color:#a7d9aa
}
button:hover{
  border-color: #a7d9aa;
}
.bg-alert{
  background-color: rgba(167,217,170,.5);
}

.highlighted{
  color:#a7d9aa;
}
option:hover{
  background-color:#a7d9aa;
}

</style>
