<template>
  <div class="w-full md:flex flex-wrap py-5 justify-between">
    <div
      v-for="listing of listings"
      :key="listing._id"
      class="bg-white rounded-md flex-none cursor-pointer hover:opacity-80 max-w-[355px] mb-4 md:mb-0"
      @click="setSelectedListing(listing)"
      style="margin: 0 auto; padding-left:.5rem; padding-right:.5rem;"
    >
      <img
        :src="listing.images[0].url"
        class="aspect-video h-[200px] rounded-md"
        v-if="listing && listing.images && listing.images[0]"
      />
      <div class="w-full px-5 text-xl">
        <span class="py-3 line-clamp-2 ">
          {{ listing.location.address.suburb }},
          {{ listing.location.address.city }}
        </span>
        <small class="line-clamp-1">{{
          listing.location.address.streetAddress
        }}</small>
      </div>
      <div>
        <span class="line-clamp-1 inline-flex items-center px-5">
          <img src="@/assets/icons/bed.png" style="width: 25px; margin-right: 5px;"/>
          <small>{{ listing.description.bedrooms }}</small>
          &nbsp;&nbsp;&nbsp;
          <img src="@/assets/icons/Icon1.png" style="width: 25px; margin-right: 5px;"/>
          <small>{{ listing.description.bathrooms }}</small>
          &nbsp;&nbsp;&nbsp;
          <img src="@/assets/icons/house_cost.svg" style="width: 25px; margin-right: 5px;"/>
          <small>${{ listing.description.rent }}/w</small>
        </span>
      </div>
        
      <br />
    </div>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import { useListingStore } from "@/store/listing";

export default {
  name: "ListingsReel",
  props: ["listings"],
  computed: {
    ...mapWritableState(useListingStore, ["selectedListing"]),
  },
  methods: {
    setSelectedListing(listing) {
      this.selectedListing = listing;
    },
  },
};
</script>

<style scoped>
small {
  font-size: 75%
}
@media (max-width: 767px) {
  .justify-between{
    justify-content: center;
    
  }
}
</style>
